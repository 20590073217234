import { DSButton, DSText } from '@zoe/ds-web'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { FragmentComponent } from '@/types/graphql'
import React, { useContext } from 'react'
import { TrackingContext } from '@/context/trackingContext'
import { DSFlex, DSIcon, DSSpacer } from '@zoe/ds-web'
import { DSButtonColourScheme, DSButtonSize, DSButtonVariant, DSButtonWidth } from '@/components/ds/Button/Button'
import styled from 'styled-components'

const ButtonWrapper = styled.div<{ width?: DSButtonWidth }>`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.width === 'full' ? '100%' : 'auto')};
`

const OverlayAnchor = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

type DsButtonRecordType = {
  __typename: 'DsButtonRecord'
  id: string
  label: string
  variant: DSButtonVariant
  colourScheme: DSButtonColourScheme
  size: DSButtonSize
  width: DSButtonWidth
  mobileWidth: DSButtonWidth
  rounded: boolean
  url: string
  customTracking?: string
  withArrow?: boolean
  testId?: string
}

const fragment = `
  fragment DsButtonBlockFragment on DsButtonRecord {
    __typename
    id
    label
    url
    variant
    colourScheme
    size
    width
    mobileWidth
    rounded
    customTracking
    withArrow
    testId
  }
`

const ButtonContent = ({ record }) => (
  <DSFlex direction="row" justifyContent="center" alignItems="center">
    <DSText as="span" variant="fluid-paragraph-200" weight="semiBold">
      {record?.label}
    </DSText>
    {record.withArrow && (
      <>
        <DSSpacer size={8} direction="horizontal" />
        <DSIcon name="arrow-right" size={16} />
      </>
    )}
  </DSFlex>
)
export const DsButtonBlock: FragmentComponent<{}, DsButtonRecordType> = ({ record }) => {
  const { section } = useContext(TrackingContext)

  const handleOnPress = () => {
    trackCtaClick(section || 'cms', record.customTracking ? record.customTracking : record.label)
    // If the URL contains an anchor, scroll to it
    if (record?.url?.includes('#')) {
      const anchor = record.url.split('#')[1]
      const element = document.getElementById(anchor)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }
  }

  if (record?.url?.includes('#')) {
    return (
      <DSButton
        colorScheme={record?.colourScheme}
        size={record?.size}
        variant={record?.variant}
        width={record?.width}
        dangerouslySet__rounded={record?.rounded}
        onPress={handleOnPress}
        {...(record?.testId && {
          testId: record.testId,
          id: record.testId,
        })}
      >
        <ButtonContent record={record} />
      </DSButton>
    )
  }

  return (
    <ButtonWrapper width={record?.width}>
      <DSButton
        colorScheme={record?.colourScheme}
        size={record?.size}
        variant={record?.variant}
        width={record?.width}
        dangerouslySet__rounded={record?.rounded}
      >
        <ButtonContent record={record} />
      </DSButton>
      <OverlayAnchor
        href={record?.url}
        aria-label={record?.label}
        onClick={handleOnPress}
        {...(record?.testId && {
          'data-testid': record.testId,
          id: record.testId,
        })}
      />
    </ButtonWrapper>
  )
}

DsButtonBlock.fragment = fragment
DsButtonBlock.recordName = 'DsButtonBlock'
